<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-card>
          <v-card-title class="elevation-1">
            Products
            <v-spacer></v-spacer>
            <v-col cols="12" sm="3">
              <v-text-field
                v-model="keyword"
                append-icon="mdi-magnify"
                solo
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-card-title>
          <v-layout wrap>
            <v-flex v-for="(item, i) in product" :key="i" md3 pa-4>
              <v-card tile flat style="cursor: pointer"  @click="info(item._id)"
                ><br />
                <v-img :src="baseURL + item.photos[0]" height="200px" contain  > 
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="#FF2323"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-layout wrap justify-center px-2>
                  <v-flex
                    pt-2
                    xl12
                    text-left
                    style="line-height: 16px;"
                   
                  >
                    <span style="font-size: 13px; color: #000000">
                      {{ item.productname }}
                    </span>
                    <br /><br/>
                    <!-- <span style="font-size: 13px; color: #000000">
                      {{ item.subcategory.name }}
                    </span> -->
                    <!-- <br /> -->
                    <span style="font-size: 13px; color: #000000">
                      Rs. {{ item.price }}
                    </span>
                    <span
                      class="pl-12 text-decoration-line-through"
                      style="font-size: 13px; color: #ff0000"
                       v-if="item.offer > 0"
                    >
                      Rs. {{ item.orginalprice }}
                    </span>
                    <br />
                    <span>
                      <v-rating
                        v-model="item.rating"
                        color="#FFD341"
                        background-color="#e0e0e0"
                        empty-icon="$ratingFull"
                        half-increments
                        readonly
                        hover
                        small
                      ></v-rating>
                    </span>
                    <br />
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
          <div class="pt-2">
            <v-pagination
              :length="pages"
              :total-visible="7"
              v-model="currentPage"
            ></v-pagination>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["sellerId"],
  data() {
    return {
      ServerError: false,
      appLoading: false,
      keyword: "",
      currentPage: 1,
      pages: 0,
      count: 12,
      totalData: 0,
      totalRows: 0,
      product: [],
      data: [],
      category: [],
    };
  },
  watch: {
    keyword() {
      this.getSearch();
    },
    currentPage() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getSearch() {
      axios({
        url: "/product/all",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {          
          sellerid:this.sellerId,
          keyword: this.keyword,         
        },
      })
        .then((response) => {
          this.product = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/product/getbyseller",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
          sort: this.sortType,
          sellerid: this.sellerId,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.product = response.data.data;
          this.pages = response.data.pages;
          this.data = response.data.data.category;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    info(pid) {
      this.$router.push("/productDetails/" + pid);
    },
    edit(pid) {
      this.$router.push({ name: "editProduct", params: { productid: pid }});
    },
  },
};
</script>